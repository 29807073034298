import { fillCircle } from './utils'
import { GameStat } from './gamestat'


export class Board {
  M: number
  N: number
  gridSpacing: number
  chessRadius: number
  margin: number

  playerChessColor: Array<string>
  playerFillColor: Array<string>
  // canvas: HTMLCanvasElement
  // private ctx: CanvasRenderingContext2D
  // a MxN board.
  constructor(M: number, N: number) {
    // this.canvas = canvas
    // this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D

    this.M = M
    this.N = N

    // the grid's spacing constant
    // TODO expose these fields
    this.gridSpacing = 50
    this.chessRadius = 17
    this.margin = 50
    // colors
    this.playerChessColor = ['rgb(200, 0, 0)', 'rgb(200, 200, 200)']
    this.playerFillColor = ['rgb(100, 50, 0)', 'rgb(100, 100, 100)']
  }

  // draw the entire board, gs: GameStat
  // draw(gs: GameStat) {
  //   this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
  //   this.ctx.fillStyle = 'rgb(0, 0, 0)'
     
  //   for (let m = 0; m < this.M; m++) {
  //     for (let n = 0; n < this.N; n++) {
  //       const p = gs.occupation[m][n]
  //       if (p != -1) {
  //         this.drawSite(m, n, p)
  //       }
  //     }
  //   }

  //   this.drawGrid()

  //   for (let m = 0; m < this.M + 1; m++) {
  //     for (let n = 0; n < this.N + 1; n++) {
  //       const c = gs.chesses[m][n]
  //       if (c !== null) {
  //         this.drawChess(c.m, c.n, c.player)
  //       }
  //     }
  //   }

  //   // score
  //   this.ctx.fillStyle = 'rgb(0, 0, 0)'
  //   this.ctx.font = '24px serif'
  //   this.ctx.fillText('player 1: ' + gs.score[0], 380, 400)
  //   this.ctx.fillText('player 2: ' + gs.score[1], 380, 450)
  // }

  // // draw the whole chess board.
  // drawGrid() {
  //   const margin = 50
  //   this.ctx.beginPath()
  //   const x0 = this.getX(this.N)
  //   for (let m = 0; m <= this.M; m ++) {
  //     const y = this.getY(m)
  //     this.ctx.moveTo(margin, y)
  //     this.ctx.lineTo(x0, y)
  //   }
  //   const y0 = this.getY(this.M)
  //   for (let n = 0; n <= this.N; n ++) {
  //     const x = this.getX(n)
  //     this.ctx.moveTo(x, margin)
  //     this.ctx.lineTo(x, y0)
  //   }
  //   this.ctx.closePath()
  //   this.ctx.stroke()
  // }

  // drawChess(m: number, n: number, player: number) {
  //   const x = this.getX(n)
  //   const y = this.getY(m)
  //   this.ctx.fillStyle = this.playerChessColor[player]
  //   fillCircle(this.ctx, x, y, this.chessRadius)
  // }

  // drawSite(m: number, n: number, player: number) {
  //   const x = this.getX(n)
  //   const y = this.getY(m)
  //   const g = this.gridSpacing
  //   this.ctx.fillStyle = this.playerFillColor[player]
  //   this.ctx.fillRect(x, y, g, g)
  // }

  // canvas coordinate to lattice position tranform
  getX(n: number) {
    return this.margin + n * this.gridSpacing
  }

  getY(m: number) {
    return this.margin + m * this.gridSpacing
  }

  getM(y: number) {
    return Math.round((y - this.margin) / this.gridSpacing)
  }

  getN(x: number) {
    return Math.round((x - this.margin) / this.gridSpacing)
  }

  inRange(x: number, y: number, r: number) {
    if (x > this.margin + this.N * this.gridSpacing + r || y > this.margin + this.M * this.gridSpacing + r) {
      return false
    }
    const dx = x - this.margin - this.getN(x) * this.gridSpacing
    const dy = y - this.margin - this.getM(y) * this.gridSpacing
    // const dy = (y - this.margin) % this.gridSpacing
    const dr2 = dx * dx + dy * dy
    return dr2 < r * r
  }
}

export function draw(board: Board, gs: GameStat, canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  ctx.fillStyle = 'rgb(0, 0, 0)'
     
  for (let m = 0; m < board.M; m++) {
    for (let n = 0; n < board.N; n++) {
      const p = gs.occupation[m][n]
      if (p !== -1) {
        drawSite(board, ctx, m, n, p)
      }
    }
  }

  drawGrid(board, ctx)

  for (let m = 0; m < board.M + 1; m++) {
    for (let n = 0; n < board.N + 1; n++) {
      const c = gs.chesses[m][n]
      if (c !== null) {
        drawChess(board, ctx, c.m, c.n, c.player)
      }
    }
  }

  // score
  ctx.fillStyle = 'rgb(0, 0, 0)'
  ctx.font = '24px serif'
  ctx.fillText('player 1: ' + gs.score[0], 380, 400)
  ctx.fillText('player 2: ' + gs.score[1], 380, 450)
}

// draw the whole chess board.
function drawGrid(board: Board, ctx: CanvasRenderingContext2D) {
  const margin = 50
  ctx.beginPath()
  const x0 = board.getX(board.N)
  for (let m = 0; m <= board.M; m ++) {
    const y = board.getY(m)
    ctx.moveTo(margin, y)
    ctx.lineTo(x0, y)
  }
  const y0 = board.getY(board.M)
  for (let n = 0; n <= board.N; n ++) {
    const x = board.getX(n)
    ctx.moveTo(x, margin)
    ctx.lineTo(x, y0)
  }
  ctx.closePath()
  ctx.stroke()
}

function drawChess(board: Board, ctx: CanvasRenderingContext2D, m: number, n: number, player: number) {
  const x = board.getX(n)
  const y = board.getY(m)
  ctx.fillStyle = board.playerChessColor[player]
  fillCircle(ctx, x, y, board.chessRadius)
}

function drawSite(board: Board, ctx: CanvasRenderingContext2D, m: number, n: number, player: number) {
  const x = board.getX(n)
  const y = board.getY(m)
  const g = board.gridSpacing
  ctx.fillStyle = board.playerFillColor[player]
  ctx.fillRect(x, y, g, g)
}
