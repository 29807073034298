import TelegramBot from 'node-telegram-bot-api'
import { GameStat } from './games/SpaceNinja'


export class Match {
  player1: TelegramBot.User
  player2?: TelegramBot.User
  available: boolean
  lastActivateTime: number

  // games info
  gameStat: GameStat

  constructor(player1: TelegramBot.User) {
    this.player1 = player1
    this.available = true
    this.lastActivateTime = new Date().getTime()
    // TODO
    this.gameStat = new GameStat(5, 5)    
  }

  addPlayer(player: TelegramBot.User): boolean {
    if (this.player2 === undefined) {
      if (this.isActivate()) {
        this.touch()
        this.player2 = player
        return true
      }
    }
    return false
  }

  // get order
  getPlayer(): number {
    return this.gameStat.currentPlayer === 0 ? this.player1.id : this.player2.id
  }

  touch() {
    this.lastActivateTime = new Date().getTime()
  }

  isActivate(): boolean {
    if (!this.available) {
      return false
    } else {
      const now = new Date().getTime()
      // 1 hour
      const result = now - this.lastActivateTime < 60 * 60 * 1000
      if (!result) this.available = false
      return result
    }
  }
}

class MatchManager {
  playerCount: number
  matchsInfo: Array<Match>
  matchsMap: Map<number, Match>

  constructor() {
    this.playerCount = 0
    this.matchsInfo = []
    this.matchsMap = new Map<number, Match>()
  }

  get(id: number) {
    return this.matchsMap.get(id)
  }
}

const playerCount = 0
const matchsInfo = new Array<Match>()
const matchsMap = new Map<number, Match>()

export function set(userId: number, match: Match): Map<number, Match> {
  return matchsMap.set(userId, match)
}

export function get(userId: number): Match {
  return matchsMap.get(userId)
}

export function getPlayer(userId: number): number {
  return matchsMap.get(userId).getPlayer()
}

export function move(userId: number, m: number, n: number) {
  const match = matchsMap.get(userId)
  match.touch()
  match.gameStat.move(m, n)
  console.log("match 1", matchsMap.get(1))
  console.log("match 2", matchsMap.get(2))
}

export function getGameStat(userId: number): GameStat {
  return matchsMap.get(userId).gameStat
}
