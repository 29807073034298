import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Ninja from './Ninja'
import reportWebVitals from './reportWebVitals'
import * as MatchManager from './state'
import TelegramBot from 'node-telegram-bot-api'



// test
const match_test = new MatchManager.Match({ id: 1, is_bot: false, first_name: "a" })
match_test.addPlayer({id: 2, is_bot: false, first_name: "b"})
MatchManager.set(1, match_test)
MatchManager.set(2, match_test)

const path = window.location.pathname.substr(1)
const userId = Number(path)
const match = MatchManager.get(userId)
console.log(userId, match)

ReactDOM.render(
  <React.StrictMode>
    <Ninja userId={match !== undefined ? userId : undefined} />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
