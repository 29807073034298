export interface Move {
  count: number,
  player: number,
  m: number,
  n: number
}

export class GameStat {
  M: number
  N: number
  moves: Move[]
  boundary: Move[]
  score: number[]
  occupation: number[][]
  chesses: Move[][]
  count: number
  // 0 for 1st player, 1 for 2nd player
  currentPlayer: number
  constructor(M: number, N: number) {
    this.M = M
    this.N = N
    this.moves = []
    this.boundary = []
    this.score = [0, 0]
    this.occupation = new Array<Array<number>>(M)
    for (let i = 0; i < M; i++) {
      this.occupation[i] = new Array<number>(N).fill(-1)
    }
    this.chesses = new Array<Array<Move>>(M + 1)
    for (let i = 0; i < M + 1; i++) {
      this.chesses[i] = new Array<Move>(N + 1).fill(null)
    }
    this.count = 0
    this.currentPlayer = 0
  }

  isEmpty(m: number, n: number) {
    return this.chesses[m][n] == null
  }

  calcOccupation(m: number, n: number) {
    let c = new Array(4)
    c[0] = this.chesses[m][n]
    c[1] = this.chesses[m][n + 1]
    c[2] = this.chesses[m + 1][n]
    c[3] = this.chesses[m + 1][n + 1]
    if (c.reduce((x, y) => x && y) == null) {
      return
    }
    const winner = c.reduce((x, y) => {
      return x.count < y.count ? y : x
    }).player
    this.occupation[m][n] = winner
  }

  calcScore() {
    let score = [0, 0]
    for (let arr of this.occupation) {
      for (let p of arr) {
        if (p !== -1) {
          score[p]++
        }
      }
    }
    this.score = score
  }

  addMove(m: number, n: number, player: number) {
    if (!this.isEmpty(m, n) || !this.isValidPos(m, n)) {
      // early stop
      return
    }
    const move: Move = {
      count: this.count,
      player: player,
      m: m,
      n: n
    }
    this.count++
    this.moves.push(move)
    this.chesses[m][n] = move

    // calculate occupation, mind the boundaries
    if (m > 0 && n !== this.N) {
      this.calcOccupation(m - 1, n)
    }
    if (n > 0 && m !== this.M) {
      this.calcOccupation(m, n - 1)
    }
    if (m !== this.M && n !== this.N) {
      this.calcOccupation(m, n)
    }
    if (m > 0 && n > 0) {
      this.calcOccupation(m - 1, n - 1)
    }

    this.calcScore()
  }

  move(m: number, n: number) {
    if (m < 0 || m > this.M || n < 0 || n > this.N) {
      return
    }
    if (!this.isEmpty(m, n) || !this.isValidPos(m, n)) {
      // early stop
      return
    }
    this.addMove(m, n, this.currentPlayer)
    this.currentPlayer = 1 - this.currentPlayer
  }

  // only short-range interaction
  isValidPos(m: number, n: number) {
    // the first move can be arbitrary
    if (this.count === 0) {
      return true
    }
    // return this.boundary.find(val => val == (m, n))
    let c = []
    if (m > 0) { c.push(this.chesses[m - 1][n]) }
    if (m < this.M) { c.push(this.chesses[m + 1][n]) }
    if (n > 0) { c.push(this.chesses[m][n - 1]) }
    if (n < this.N) { c.push(this.chesses[m][n + 1]) }
    // if find chess around the position
    return c.reduce((x, y) => x || y) !== null
  }
}
