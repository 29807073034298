import React, { useRef, useState } from 'react'
import * as SpaceNinja from './games/SpaceNinja'
import * as MatchManager from './state'


export default function Ninja({userId}: {userId: number}) {
  // const matchRef = useRef(match)
  console.log("userId: ", userId)
  const [M, setM] = useState(5)
  const [N, setN] = useState(5)
  const gameStatRef = useRef(new SpaceNinja.GameStat(M, N))
  const ref = useRef() as React.MutableRefObject<HTMLCanvasElement>
  const boardRef = useRef(new SpaceNinja.Board(M, N))
  // if (userId !== undefined) {
  //   gameStatRef.current = match.gameStat
  // }

  const clickHandler = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (userId !== undefined) {
      if (userId !== MatchManager.getPlayer(userId)) {
        return
      }
    }

    const canvas = ref.current
    const board = boardRef.current
    const r = 10
    const x = event.pageX - (canvas.offsetLeft + canvas.clientLeft)
    const y = event.pageY - (canvas.offsetTop + canvas.clientTop)
    if (board.inRange(x, y, r)) {
      const m = board.getM(y)
      const n = board.getN(x)
      if (userId !== undefined) {
        MatchManager.move(userId, m, n)
      } else {
        gameStatRef.current.move(m, n)
      }
      // matchRef.current.gameStat.move(m, n)
      // if (player !== undefined) {
      //   match.gameStat = gameStatRef.current
      // }
    }
  }

  const requestRef = useRef() as React.MutableRefObject<number>
  const animate = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // SpaceNinja.draw(boardRef.current, gameStatRef.current, ref.current)
    if (userId !== undefined) {
      console.log(MatchManager.getGameStat(userId))
      SpaceNinja.draw(boardRef.current, MatchManager.getGameStat(userId), ref.current)
    } else {
      SpaceNinja.draw(boardRef.current, gameStatRef.current, ref.current)
    }
    // SpaceNinja.draw(boardRef.current, MatchManager.getGameStat(userId), ref.current)
    // gameStatRef.current = match.gameStat
    requestAnimationFrame(animate)
  }
  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  }, [])

  return (
    <canvas ref={ref} onClick={clickHandler} width={500} height={500}>
    </canvas>
  )
}
